<template>
  <div id="user-page">
    <div id="page-header">
      <h1 id="page-title" class="h2">Persoonlijke gegevens</h1>
      <ul id="page-actions" class="nav">
        <li class="nav-item">
          <a href="#" role="button" class="nav-link" data-toggle="modal" data-target="#updateUserInfoModal" aria-label="Gegevens aanpassen">
            Bewerken
          </a>
        </li>
        <li class="nav-item">
          <a href="#" role="button" class="nav-link" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
            </svg>
          </a>
        </li>
      </ul>
    </div>

    <div id="content" class="row mb-4">
      <div class="col">
        <div id="primary-card" class="card">
          <div class="card-body p-0 d-flex" style="min-height: 138px;">
            <img class="profile-photo rounded-circle mr-4" src="https://randomuser.me/api/portraits/lego/1.jpg">
            <div class="d-flex flex-column flex-fill p-3">
              <div class="flex-fill">
                <h2>Mevr. Marianne Noten</h2>
                <ul class="list-inline">
                  <li class="list-inline-item"><i class="bi bi-telephone-fill"></i> {{ current_user.phone}}</li>
                  <li class="list-inline-item"><i class="bi bi-envelope-fill"></i> {{ current_user.email }}</li>
                </ul>
              </div>
<!--              <img @click='isHidden = !isHidden' class="d-block mx-auto" :src="require('@/assets/img/chevron-down.svg')">-->
              <transition name="slide">
                <div v-show='!isHidden'>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque lacinia risus vel euismod pellentesque. Sed est turpis, elementum eget ligula non, consequat molestie leo. Curabitur vel tortor sit amet quam efficitur malesuada. Nullam nec semper ligula. Nunc venenatis nibh suscipit rhoncus porttitor. Nullam finibus eros sed consequat vestibulum. Sed fringilla metus id erat venenatis porttitor. Praesent gravida in velit ut pellentesque.</p>
                  <p>Proin suscipit luctus eros sit amet consectetur. Ut varius leo vel tempus blandit. Sed dictum metus non ultricies feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac justo sit amet enim gravida ultricies quis in diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non euismod neque. Proin leo dolor, finibus eu rutrum eu, lacinia id purus. Ut eget sapien vulputate, accumsan nibh nec, interdum ante.</p>
                </div>
              </transition>
            </div>
            <div style="width: 138px; flex: 1 0 auto;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-secondary font-weight-medium mb-4">Diagnose</div>

    <div class="row">
      <div class="col mb-4">
        <div class="card mnnu-card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card mnnu-card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card mnnu-card">
          <div class="card-body" style="height: 200px">
          </div>
        </div>
      </div>
    </div>

    <div class="text-secondary font-weight-medium mb-4">Behandelplan</div>

    <div class="row">
      <div class="col mb-4">
        <div class="card mnnu-card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card mnnu-card">
          <div class="card-body" style="height: 300px">
          </div>
        </div>
      </div>
    </div>

    <modal id="pageHelpModal" title="Persoonlijke gegevens">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ullamcorper aliquet nulla a facilisis. Vivamus aliquam ex ut justo suscipit, eu vestibulum justo dictum. Nulla varius, tortor nec tempor pellentesque, erat velit tincidunt libero, eu fringilla erat massa volutpat turpis. Aliquam et nibh massa. Vivamus nulla ipsum, ornare vitae felis ut, fringilla venenatis risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sed ornare nisl. Mauris nec nunc quis purus varius aliquet. Integer a pellentesque ligula. Etiam et imperdiet urna.</p>
    </modal>

    <modal id="updateUserInfoModal" title="Gegevens bewerken">
      <form>
        <div class="form-group">
          <label for="email">E-mailadres</label>
          <input :value="current_user.email" @input="updateEmail" type="email" class="form-control" id="email">
        </div>
        <div class="form-group">
          <label for="phone">Telefoonnummer</label>
          <input :value="current_user.phone" @input="updatePhone" type="tel" class="form-control" id="phone">
        </div>
        <div class="form-group">
          <label for="address">Adres</label>
          <input :value="current_user.address" @input="updateAddress" type="text" class="form-control" id="address">
        </div>
        <div class="form-group">
          <label for="zip">Postcode</label>
          <input :value="current_user.zip" @input="updateZip" type="text" class="form-control" id="zip">
        </div>
        <div class="form-group">
          <label for="city">Woonplaats</label>
          <input :value="current_user.city" @input="updateCity" type="text" class="form-control" id="city">
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/Modal'
import { mapState } from 'vuex'

export default {
  name: 'User',
  components: { Modal },
  data () {
    return {
      isHidden: true,
      user: { }
    }
  },
  mounted () {
    this.fetchUser()
  },
  computed: {
    ...mapState([
      'current_user'
    ])
  },
  methods: {
    fetchUser () {
      return this.$http
        .get('//jsonplaceholder.typicode.com/users/1')
        .then((response) => {
          this.user = response.data
        })
        .catch(error => console.log(error))
    },
    updateEmail (e) {
      this.$store.commit('setCurrentUserEmail', e.target.value)
    },
    updatePhone (e) {
      this.$store.commit('setCurrentUserPhone', e.target.value)
    },
    updateAddress (e) {
      this.$store.commit('setCurrentUserAddress', e.target.value)
    },
    updateZip (e) {
      this.$store.commit('setCurrentUserZip', e.target.value)
    },
    updateCity (e) {
      this.$store.commit('setCurrentUserCity', e.target.value)
    }
  }
}
</script>
